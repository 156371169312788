<template>
  <div class="pb-nav">
    <TitleHeader :title="title" :link="'/'" />
    <div class="basic_set_list" v-if="record_list&&record_list.length>0" >
      <ul class="basic_list d-flex flex-wrap flex-column p-3" >
        <li v-for="(item,index) in record_list" :key="index" class="d-flex justify-content-between mb-3">
            <div class="left d-flex">
                <!-- <img :src="item.art" class="border ratio ratio-1x1 img-fluid" alt="album art" style="width:70px;"> -->
                <div class="album_info d-flex flex-column justify-content-center pe-3">
                    <span class="text-truncate">{{ item.name }}</span>
                    <small class="text-truncate">{{ item.artist }}</small>
                </div>
            </div>
            <div class="right d-flex gap-2 py-3">
                <button class="btn badge border" :class="{'checked':now_play_idx==index}" @click="playFn(item, index);" variant="info">
                  <i class="fas fa-pause fa-lg" v-if="now_play_idx==index"></i>
                  <i class="fal fa-play fa-lg" v-else></i>
                </button>
                <button class="btn badge border" @click="delayedAlert(index)" variant="info"><i class="fal fa-trash fa-lg"></i></button>
            </div>
        </li>
      </ul>
    </div>
    <div class="p-3 empty_data" v-else>
      <p class="text-center py-3">
          녹음된 노래가 없어요.
      </p>
    </div>
    <audio class="audio" @ended="endFn()">
      <source class="now_play" :src="now_play_url">
    </audio>
    <div class="alert alert-success d-flex align-items-center position-fixed max-w-px-768 start-50 translate-middle-x shadow bottom-0 mb-2" :class="{'alert-danger':alertType==='C'}" style="width:95%" role="alert"
        v-if="showAlert">
        <i class="fas fa-check me-2"></i> 
        <div v-if="alertType2==='reservation'">
            {{alertType==='C'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
        </div>
        <div v-else>
            {{alertType==='C'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
        </div>
    </div>
  </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
  data(){
    return{
      title:'녹음',
      showAlert: false,
      alertType:'',
      alertType2:'',

      audio: null,
      now_play_url: '',
      now_play_idx: -1,
      click_play_idx: -1,
    }
  },
  mounted(){
      this.$EventBus.$emit('HideHeader');
      this.audio = document.querySelector(".audio");
  },
  destroyed(){
      this.$EventBus.$emit('ShowHeader')
      this.audio.pause();
  },
  computed:{
    record_list(){
      return this.$store.state.record_song
    }
  },
  methods:{
    delayedAlert(index) {
      this.$store.commit('removeRecord',index);
      this.audio.pause();
      this.now_play_idx=-1;
    },
    playFn(item, index){
      if( this.click_play_idx != index ){
          this.now_play_idx = index;
          this.click_play_idx = index;
          this.now_play_url = item.url;
          this.audio.load();
          this.audio.play();
      }else{
        if( this.audio.paused ){
          this.now_play_idx = index;
          this.audio.play();
        }else{
          this.audio.pause();
          this.now_play_idx = -1;
        }
      }
    },
    endFn(){
      this.now_play_idx = -1;
    }
  }
}
</script>